export default {
  namespaced: true,
  state: {
    isModalAddEditOpen: false,
    isModalResetOpen: false,
    editPerson: null,
    userLoginInfo: {
      userName: '',
      email: '',
      email_pin: '',
      password: '',
      pin: '',
      autoPassword: true,
      sendPassword: true,
      askChangePassword: true,
      pin_switch: false,
      autoPin: true,
      sendPin: true,
      askChangePin: true,
      rule_1: false,
      rule_2: false,
      rule_3: false,
      pin_rule_1: false,
      pin_rule_2: false
    }
  },
  getters: {},
  mutations: {
    UPDATE_MODAL_ADD_EDIT_TOGGLE(state, val) {
      state.isModalAddEditOpen = val;
    },
    UPDATE_MODAL_RESET_TOGGLE(state, val) {
      state.isModalResetOpen = val;
    },
    UPDATE_EDIT_PERSON(state, val) {
      state.editPerson = val;
    },
    UPDATE_USER_LOGIN(state, val) {
      state.userLoginInfo = val;
    }
  }
};
