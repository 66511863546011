export default {
  namespaced: true,
  state: {
    isModalOptionsListOpen: false,
    isModalAddDataFieldOpen: false,
    isModalAddGroupOpen: false,
    isModalDeleteOpen: false,
    isModalNewGroupOpen: false,
    dragList: [],
    dragListStart: [],
    optionsList: [{ text: '' }, { text: '' }, { text: '' }],
    selectedChecks: 0
  },
  getters: {},
  mutations: {
    UPDATE_MODAL_OPTIONS_LIST_TOGGLE(state, val) {
      state.isModalOptionsListOpen = val;
    },
    UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(state, val) {
      state.isModalAddDataFieldOpen = val;
    },
    UPDATE_MODAL_ADD_GROUP_TOGGLE(state, val) {
      state.isModalAddGroupOpen = val;
    },
    UPDATE_OPTIONS_LIST(state, val) {
      state.optionsList = val;
    },
    UPDATE_DRAG_LIST(state, val) {
      state.dragList = val;
    },
    UPDATE_DRAG_LIST_START(state, val) {
      state.dragListStart = val;
    },
    UPDATE_SELECTED_CHECKS(state, val) {
      state.selectedChecks = val;
    },
    UPDATE_MODAL_DELETE(state, val) {
      state.isModalDeleteOpen = val;
    },
    UPDATE_MODAL_NEW_GROUP_TOGGLE(state, val) {
      state.isModalNewGroupOpen = val;
    }
  }
};
