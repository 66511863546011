export default {
  namespaced: true,
  state: {
    modalTitle: 'DepartmentPage.Modal.Title',
    isModalAddEditOpen: false,
    isModalAddRoleOpen: false,
    isModalPeopleOpen: false,
    isModalInfoOpen: false,
    isModalLinkPeopleOpen: false,
    isModalOptionsPeopleOpen: false,
    isModalDeleteOpen: false,
    isModalSnackBarDelete: false,
    rolesList: [],
    addedRoles: [],
    peopleList: [],
    peopleToLink: [],
    dragList: [],
    dragListStart: [],
    selectedChecks: 0
  },
  getters: {},
  mutations: {
    UPDATE_MODAL_TITLE(state, val) {
      state.modalTitle = val;
    },
    UPDATE_MODAL_ADD_EDIT_TOGGLE(state, val) {
      state.isModalAddEditOpen = val;
    },
    UPDATE_MODAL_ADD_ROLE_TOGGLE(state, val) {
      state.isModalAddRoleOpen = val;
    },
    UPDATE_MODAL_PEOPLE_TOGGLE(state, val) {
      state.isModalPeopleOpen = val;
    },
    UPDATE_MODAL_INFO_TOGGLE(state, val) {
      state.isModalInfoOpen = val;
    },
    UPDATE_MODAL_LINK_PEOPLE_TOGGLE(state, val) {
      state.isModalLinkPeopleOpen = val;
    },
    UPDATE_MODAL_OPTIONS_TOGGLE(state, val) {
      state.isModalOptionsPeopleOpen = val;
    },
    UPDATE_MODAL_DELETE(state, val) {
      state.isModalDeleteOpen = val;
    },
    UPDATE_MODAL_DELETE_SNACK_BAR(state, val) {
      state.isModalSnackBarDelete = val;
    },
    UPDATE_ROLES_LIST(state, val) {
      state.rolesList = val;
    },
    UPDATE_PEOPLE_LIST(state, val) {
      state.peopleList = val;
    },
    UPDATE_PEOPLE_TO_LINK_LIST(state, val) {
      state.peopleToLink = val;
    },
    UPDATE_ADDED_ROLES(state, val) {
      state.addedRoles = val;
    },
    UPDATE_DRAG_LIST(state, val) {
      state.dragList = val;
    },
    UPDATE_DRAG_LIST_START(state, val) {
      state.dragListStart = val;
    },
    UPDATE_SELECTED_CHECKS(state, val) {
      state.selectedChecks = val;
    }
  }
};
