export default {
  namespaced: true,
  state: {
    modalTitle: 'DepartmentPage.Modal.Title',
    isModalAddEditOpen: false,
    isModalGroupOpen: false,
    isModalWorkCenterOpen: false,
    isModalPeopleOpen: false,
    isModalInfoOpen: false,
    isModalLinkPeopleOpen: false,
    isModalOptionsPeopleOpen: false,
    isModalResourceOpen: false,
    isModalResourceInfoOpen: false,
    isModalLinkResourceOpen: false,
    isModalOptionsResourceOpen: false,
    isModalDeleteOpen: false,
    isModalSnackBarDelete: false,
    dragList: [],
    dragListStart: [],
    rolesList: [],
    peopleList: [],
    peopleToLink: [],
    resourceList: [],
    resourceToLink: [],
    selectedChecks: 0
  },
  mutations: {
    UPDATE_MODAL_TITLE(state, val) {
      state.modalTitle = val;
    },
    UPDATE_MODAL_ADD_EDIT_TOGGLE(state, val) {
      state.isModalAddEditOpen = val;
    },
    UPDATE_MODAL_GROUP_TOGGLE(state, val) {
      state.isModalGroupOpen = val;
    },
    UPDATE_MODAL_WORKCENTER_TOGGLE(state, val) {
      state.isModalWorkCenterOpen = val;
    },
    UPDATE_MODAL_PEOPLE_TOGGLE(state, val) {
      state.isModalPeopleOpen = val;
    },
    UPDATE_MODAL_INFO_TOGGLE(state, val) {
      state.isModalInfoOpen = val;
    },
    UPDATE_MODAL_LINK_PEOPLE_TOGGLE(state, val) {
      state.isModalLinkPeopleOpen = val;
    },
    UPDATE_MODAL_OPTIONS_TOGGLE(state, val) {
      state.isModalOptionsPeopleOpen = val;
    },
    UPDATE_MODAL_RESOURCE_TOGGLE(state, val) {
      state.isModalResourceOpen = val;
    },
    UPDATE_MODAL_RESOURCE_INFO_TOGGLE(state, val) {
      state.isModalResourceInfoOpen = val;
    },
    UPDATE_MODAL_LINK_RESOURCE_TOGGLE(state, val) {
      state.isModalLinkResourceOpen = val;
    },
    UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(state, val) {
      state.isModalOptionsResourceOpen = val;
    },
    UPDATE_MODAL_DELETE(state, val) {
      state.isModalDeleteOpen = val;
    },
    UPDATE_MODAL_DELETE_SNACK_BAR(state, val) {
      state.isModalSnackBarDelete = val;
    },
    UPDATE_PEOPLE_LIST(state, val) {
      state.peopleList = val;
    },
    UPDATE_RESOURCE_LIST(state, val) {
      state.resourceList = val;
    },
    UPDATE_ROLES_LIST(state, val) {
      state.rolesList = val;
    },
    UPDATE_PEOPLE_TO_LINK_LIST(state, val) {
      state.peopleToLink = val;
    },
    UPDATE_RESOURCE_TO_LINK_LIST(state, val) {
      state.resourceToLink = val;
    },
    UPDATE_DRAG_LIST(state, val) {
      state.dragList = val;
    },
    UPDATE_DRAG_LIST_START(state, val) {
      state.dragListStart = val;
    },
    UPDATE_SELECTED_CHECKS(state, val) {
      state.selectedChecks = val;
    }
  }
};
