export default {
  namespaced: true,
  state: {
    isModalAccessOpen: false,
    isModalEditRenameDeleteOpen: false,
    profileSelected: null,
    typeModalStore: '',
    modalTitleStore: '',
    isModalPeopleOpen: false,
    isModalInfoOpen: false,
    isModalLinkPeopleOpen: false,
    isModalOptionsPeopleOpen: false,
    isModalAttentionEmail: false,
    isFirstCreation: false,
    permissions: [],
    peopleToLink: [],
    peopleList: [],
    rolesList: [],
    dragList: []
  },
  getters: {},
  mutations: {
    UPDATE_MODAL_EDIT_DELETE_TOGGLE(state, val) {
      state.isModalEditRenameDeleteOpen = val;
    },
    UPDATE_PROFILE_SELECTED(state, val) {
      state.profileSelected = val;
    },
    UPDATE_TYPE_MODAL(state, val) {
      state.typeModalStore = val;
    },
    UPDATE_MODAL_TITLE(state, val) {
      state.modalTitleStore = val;
    },
    UPDATE_MODAL_ACCESS_TOGGLE(state, val) {
      state.isModalAccessOpen = val;
    },
    UPDATE_PERMISSIONS_IDS(state, val) {
      state.permissions = val;
    },
    UPDATE_MODAL_PEOPLE_TOGGLE(state, val) {
      state.isModalPeopleOpen = val;
    },
    UPDATE_MODAL_INFO_TOGGLE(state, val) {
      state.isModalInfoOpen = val;
    },
    UPDATE_MODAL_LINK_PEOPLE_TOGGLE(state, val) {
      state.isModalLinkPeopleOpen = val;
    },
    UPDATE_MODAL_OPTIONS_TOGGLE(state, val) {
      state.isModalOptionsPeopleOpen = val;
    },
    UPDATE_MODAL_ATTENTION_EMAIL(state, val) {
      state.isModalAttentionEmail = val;
    },
    UPDATE_PEOPLE_TO_LINK_LIST(state, val) {
      state.peopleToLink = val;
    },
    UPDATE_PEOPLE_LIST(state, val) {
      state.peopleList = val;
    },
    UPDATE_ROLES_LIST(state, val) {
      state.rolesList = val;
    },
    UPDATE_FIRST_CREATION_LIST(state, val) {
      state.isFirstCreation = val;
    }
  }
};
