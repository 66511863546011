export default {
  namespaced: true,
  state: {
    modalTitle: '',
    isModalResourceOpen: false,
    isModalResourceInfoOpen: false,
    isModalLinkResourceOpen: false,
    isModalOptionsResourceOpen: false,
    isModalDuplicateOpen: false,
    isModalRouteOpen: false,
    isModalManageTagsOpen: false,
    isModalLinkMaterialsOpen: false,
    isModalAddEditOpen: false,
    isModalDeleteOpen: false,
    isModalWarningDefaultRouteOpen: false,
    materialsToLink: [],
    dragList: [],
    dragListStart: [],
    rolesList: [],
    resourceList: [],
    resourceToLink: [],
    selectedChecks: 0
  },
  mutations: {
    UPDATE_MODAL_TITLE(state, val) {
      state.modalTitle = val;
    },
    UPDATE_MODAL_RESOURCE_TOGGLE(state, val) {
      state.isModalResourceOpen = val;
    },
    UPDATE_MODAL_RESOURCE_INFO_TOGGLE(state, val) {
      state.isModalResourceInfoOpen = val;
    },
    UPDATE_MODAL_LINK_RESOURCE_TOGGLE(state, val) {
      state.isModalLinkResourceOpen = val;
    },
    UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(state, val) {
      state.isModalOptionsResourceOpen = val;
    },
    UPDATE_MODAL_DUPLICATE_TOGGLE(state, val) {
      state.isModalDuplicateOpen = val;
    },
    UPDATE_MODAL_ROUTE_TOGGLE(state, val) {
      state.isModalRouteOpen = val;
    },
    UPDATE_MODAL_MANAGE_TAGS_TOGGLE(state, val) {
      state.isModalManageTagsOpen = val;
    },
    UPDATE_MODAL_LINK_MATERIALS_TOGGLE(state, val) {
      state.isModalLinkMaterialsOpen = val;
    },
    UPDATE_MODAL_ADD_EDIT_MATERIAL_OPERATION_TOGGLE(state, val) {
      state.isModalAddEditOpen = val;
    },
    UPDATE_MODAL_DELETE(state, val) {
      state.isModalDeleteOpen = val;
    },
    UPDATE_MODAL_WARNING_DEFAULT_ROUTE(state, val) {
      state.isModalWarningDefaultRouteOpen = val;
    },
    UPDATE_MATERIALS_TO_LINK_LIST(state, val) {
      state.materialsToLink = val;
    },
    UPDATE_RESOURCE_LIST(state, val) {
      state.resourceList = val;
    },
    UPDATE_ROLES_LIST(state, val) {
      state.rolesList = val;
    },
    UPDATE_RESOURCE_TO_LINK_LIST(state, val) {
      state.resourceToLink = val;
    },
    UPDATE_DRAG_LIST(state, val) {
      state.dragList = val;
    },
    UPDATE_DRAG_LIST_START(state, val) {
      state.dragListStart = val;
    },
    UPDATE_SELECTED_CHECKS(state, val) {
      state.selectedChecks = val;
    }
  }
};
