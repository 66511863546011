export default {
  namespaced: true,
  state: {
    editId: null
  },
  mutations: {
    SET_EDIT_ID(state, id) {
      state.editId = id;
    }
  }
};
